<template>
  <div
    class="domain-list-item tw-p-space-3 tw-my-space-2 sm:tw-p-space-3 hover:tw-shadow-md hover:tw-border-primary tw-group tw-transition tw-transform tw-bg-white tw-border tw-border-solid tw-rounded"
    :class="{
      ['hover:tw-bg-primary-lighter hover:tw-bg-opacity-30 hover:tw--translate-y-space-1 tw-border-jb-grey-100 tw-cursor-pointer']: !showContent,
      ['hover:tw-border-primary hover:tw-shadow-md focus-within:tw-border-primary focus-within:tw-shadow-md tw-border-jb-grey-100 tw-mb-space-4 tw-p-layout-1']: showContent,
    }"
    @click.stop="handleClickToggleContent">
    <el-collapse-transition>
      <div v-if="showContent" ref="formSlot" class="tw-mb-layout-2 tw-cursor-auto" @click.stop>
        <slot />
      </div>
    </el-collapse-transition>

    <div class="domain-list-item-row tw-flex tw-items-center tw-justify-between">
      <transition name="fade" mode="out-in">
        <div v-if="!showContent">
          <app-text class="group-hover:tw-text-primary tw-mr-space-2" weight="semi-bold">
            {{ domain.domain }}
          </app-text>
          <el-tag
            v-if="isBYOD && !isDraft"
            :type="isVerified ? 'success' : 'info'"
            class="tw-py-space-1 tw-px-space-2 tw-h-auto tw-text-xs tw-font-medium">
            {{ domain.status }}
          </el-tag>
        </div>
      </transition>

      <div class="sm:tw-w-44 tw-text-right" :class="{ ['!tw-w-full !tw-text-right']: showContent }" @click.stop>
        <el-button-group>
          <template v-if="showContent">
            <el-button
              v-if="isDraft"
              class="tw-bg-jb-indigo"
              :loading="isSaving"
              :disabled="!isFormValid"
              type="primary"
              size="mini"
              icon="el-icon-check"
              @click.stop="handleClickSave">
              <span class="sm:tw-inline-block tw-hidden">
                Save
              </span>
            </el-button>
            <el-button
              v-if="isBYOD"
              class="tw-bg-jb-indigo"
              :loading="isVerifying"
              :disabled="!isFormValid"
              type="primary"
              size="mini"
              icon="el-icon-refresh"
              @click.stop="handleClickVerify">
              <span class="sm:tw-inline-block tw-hidden">
                Verify
              </span>
            </el-button>
            <el-button
              class="tw-bg-jb-indigo"
              type="primary"
              size="mini"
              icon="el-icon-close"
              @click.stop="handleCancel">
              <span class="sm:tw-inline-block tw-hidden">
                Cancel
              </span>
            </el-button>
          </template>
          <template v-else-if="isBYOD">
            <el-button
              class="tw-bg-jb-indigo"
              type="primary"
              size="mini"
              icon="el-icon-edit"
              @click.stop="handleClickToggleContent">
              <span class="sm:tw-inline-block tw-hidden">
                Edit
              </span>
            </el-button>
            <el-button
              class="tw-bg-jb-indigo"
              type="primary"
              size="mini"
              icon="el-icon-delete"
              @click.stop="handleClickDelete">
              <span class="sm:tw-inline-block tw-hidden">
                Delete
              </span>
            </el-button>
          </template>
          <app-text v-else>
            -
          </app-text>
        </el-button-group>
      </div>
    </div>
  </div>
</template>

<script>
import AppText from '@/components/AppText.vue';

export default {
  name: 'DomainListItem',
  components: { AppText },
  props: {
    domain: {
      type: Object,
      required: true
    },
    showContent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFormValid: false
    };
  },
  computed: {
    isBYOD() {
      return this.domain.type === 'byod';
    },
    isVerified() {
      return this.domain.status === 'active';
    },
    isDraft() {
      return this.domain.status === 'draft' || this.domain.redirector_id === null;
    },
    isVerifying() {
      return !!this.domain.is_verifying;
    },
    isSaving() {
      return !!this.domain.is_saving;
    }
  },
  created() {
    this.$on('validate', valid => {
      this.isFormValid = valid;
    });
  },
  methods: {
    handleClickToggleContent() {
      if (!this.showContent) {
        this.$emit('toggle-content', this.domain);
      }
    },
    handleClickDelete() {
      this.$emit('delete', this.domain);
    },
    handleClickSave() {
      this.$emit('save', this.domain);
    },
    handleClickVerify() {
      this.$emit('verify', this.domain);
    },
    handleCancel() {
      this.$emit('cancel', this.domain);
    }
  }
};
</script>

<style lang="scss">
.domain-list-item {
  .app-button,
  .el-button {
    &:hover {
      transform: none;
    }
  }

  .el-button-group {
    @apply tw-w-9 xs:tw-w-20 sm:tw-w-44 tw-divide-y tw-divide-x-0 xs:tw-divide-y-0 xs:tw-divide-x tw-divide-white tw-divide-opacity-50;
  }

  .el-button-group > .el-button:first-child {
    @apply tw-rounded-sm tw-rounded-b-none xs:tw-rounded-sm xs:tw-rounded-r-none tw-border-r-0 xs:tw-border-r;
  }

  .el-button-group > .el-button:last-child {
    @apply tw-rounded-sm tw-rounded-t-none xs:tw-rounded-sm xs:tw-rounded-l-none;
  }
}
</style>
