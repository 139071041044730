<template>
  <div class="domain-list">
    <template v-if="isLoading">
      <div v-for="i in 3" :key="`domain-list-item-skeleton-${i}`" class="domain-list-item tw-p-space-3 tw-my-space-2 sm:tw-p-space-3 tw-border-jb-grey-50 tw-bg-white tw-border tw-border-solid tw-rounded tw-pointer-events-none">
        <div class="tw-flex tw-items-center tw-justify-between">
          <skeleton-box height="16px" :width="`${Math.round(Math.random() * 10) + 25}%`" class="tw-mx-0" />
          <skeleton-box height="16px" width="10%" class="tw-ml-space-2" />
          <div class="sm:tw-w-44 tw-w-20 tw-flex tw-justify-end">
            <skeleton-box height="36px" style="margin: 0" class="sm:tw-w-44 tw-w-20" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <slot v-for="domain in domains" :domain="domain" />
      <slot name="footer" />
    </template>
  </div>
</template>

<script>
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'DomainList',
  components: { SkeletonBox },
  props: {
    domains: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
