<template>
  <div class="organization-domains">
    <domain-list :domains="availableDomains" :is-loading="isLoading">
      <domain-list-item
        :key="`domain-${domain.redirector_id || 'draft'}`"
        slot-scope="{ domain }"
        :domain="domain"
        :show-content="showFormContent(domain)"
        @toggle-content="handleToggleForm"
        @delete="handleDeleteDomain"
        @verify="handleVerifyDomain"
        @cancel="handleFormCancel">
        <domain-form :domain="domain" :domains="domains" @save="handleSaveDomain" />
      </domain-list-item>

      <template v-slot:footer>
        <div
          v-if="!disableAddNewDomains"
          class="group tw-px-space-3 tw-py-space-2 tw-my-space-2 sm:tw-px-space-3 sm:tw-py-space-3 hover:tw-shadow-md hover:tw-border-primary tw-group hover:tw-bg-primary-lighter hover:tw-bg-opacity-30 tw-bg-jb-grey-50 tw-flex tw-items-center tw-justify-between tw-transition tw-transform tw-border tw-border-transparent tw-border-solid tw-rounded tw-cursor-pointer"
          @click="handleAddDomainButtonClick">
          <app-heading level="h6" weight="medium" color="grey" class="group-hover:tw-text-primary tw-m-0">
            Add Domain
          </app-heading>
          <el-button type="primary" size="mini" class="tw-transform-none tw-bg-jb-indigo" icon="el-icon-plus">
            Add
          </el-button>
        </div>
      </template>
    </domain-list>
  </div>
</template>

<script>
import AppHeading from '@/components/AppHeading.vue';
import DomainList from '@/components/organization-domains/DomainList.vue';
import DomainListItem from '@/components/organization-domains/DomainListItem.vue';
import DomainForm from '@/components/organization-domains/DomainForm.vue';
import _cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'OrganizationDomains',
  components: { AppHeading, DomainList, DomainListItem, DomainForm },
  data() {
    return {
      isLoading: false,
      domains: [],
      visibleForms: {}
    };
  },
  computed: {
    disableAddNewDomains() {
      return this.domains.some((d) => d.status === 'draft' || d.redirector_id === null);
    },
    availableDomains() {
      return this.domains.filter(d => d.type !== 'affiliate');
    }
  },
  created() {
    this.fetchDomains();
  },
  methods: {
    async fetchDomains() {
      try {
        this.isLoading = true;
        const domains = await this.$api.organization.domains({
          storeAction: 'fetchOrganizationPixelDomains'
        });
        this.domains = _cloneDeep(domains);
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.isLoading = false;
      }
    },
    showFormContent(domain) {
      return this.visibleForms[domain.redirector_id] !== undefined;
    },
    async handleSaveDomain(domain) {
      if (domain.is_saving) return;
      try {
        this.$set(domain, 'is_saving', true);
        const newDomain = { domain: domain.domain };
        const createdDomain = await this.$api.organization.addNewDomain({ domain: newDomain });
        this.handleFormCancel(domain);
        this.domains.push(createdDomain);
        this.$message({
          dangerouslyUseHTMLString: true,
          message: `Domain <strong>${domain.domain}</strong> saved`,
          type: 'success'
        });
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({
          message: error.message || 'There was an error saving the domain.',
          type: 'error'
        });
      } finally {
        this.$set(domain, 'is_saving', false);
      }
    },
    handleDeleteDomain(domain) {
      this.$confirm(
        'Deleting this domain will cause all attached pixels to be reassigned to the default domain. This may also take awhile to complete. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      )
        .then(() => this.domainDeleteConfirmation(domain))
        .catch(() => {});
    },
    async handleVerifyDomain(domain) {
      const domainIndex = this.domains.findIndex((d) => d.domain === domain.domain);
      try {
        this.$set(domain, 'is_verifying', true);
        await this.$api.organization.validateDomain({ domain: domain.domain });
        this.domains[domainIndex].status = 'active';
        this.$message({
          dangerouslyUseHTMLString: true,
          message: `Domain <strong>${domain.domain}</strong> verified`,
          type: 'success'
        });
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({
          message: error.message || 'We were not able to verify your domain.',
          type: 'error'
        });
        this.domains[domainIndex].status = 'unverified';
      } finally {
        this.$set(domain, 'is_verifying', false);
      }
    },
    async handleAddDomainButtonClick() {
      const newDomain = { redirector_id: null, domain: '', status: 'draft', type: 'byod' };
      this.domains.push(newDomain);
      this.handleToggleForm(newDomain, false);
    },
    handleToggleForm(domain, isShowing) {
      isShowing = isShowing !== undefined ? isShowing : this.visibleForms[domain.redirector_id];
      if (!isShowing) {
        this.$set(this.visibleForms, domain.redirector_id, true);
      } else {
        this.$delete(this.visibleForms, domain.redirector_id);
      }

      this.clearZombieDomains();
    },
    handleFormCancel(domain) {
      this.handleToggleForm(domain, true);
      this.clearZombieDomains();
    },
    clearZombieDomains() {
      if (this.visibleForms.null === undefined) {
        const index = this.domains.findIndex((d) => d.status === 'draft' || d.redirector_id === null);
        if (index !== -1) this.domains.splice(index, 1);
      }
    },
    async domainDeleteConfirmation(domain) {
      try {
        await this.$api.organization.deleteDomain({ redirector_id: domain.redirector_id });
        this.handleToggleForm(domain);
        const index = this.domains.findIndex((d) => d.redirector_id === domain.redirector_id);
        this.domains.splice(index, 1);
        this.$message({
          dangerouslyUseHTMLString: true,
          message: `Domain <strong>${domain.domain}</strong> deleted`,
          type: 'success'
        });
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({
          message: error.message || 'There was an error deleting the domain.',
          type: 'error'
        });
      }
    }
  }
};
</script>

<style lang="scss">
.organization-domains {
  .el-button {
    margin: 0;
    padding: 0;
    height: 36px;
    width: 36px;
    font-size: $--clb-font-size-xs;

    span {
      display: none;
    }

    i {
      font-size: $--clb-font-size-sm;
    }

    &:active,
    &:hover,
    &:focus {
      transform: translateY(0) !important;
    }
  }
}

@media (min-width: $--sm) {
  .organization-domains {
    .el-button {
      width: 88px;
      height: 36px;

      span {
        display: inline;
      }
    }
  }
}
</style>
