<template>
  <el-form
    ref="form"
    :model="domain"
    class="domain-form"
    @submit.prevent.native="isValid && handleSubmit()">
    <div class="domain-form-body">
      <label>
        <div class="tw-mb-space-1 tw-inline-flex tw-items-center">
          <el-popover
            placement="right"
            title="Bring Your Own Domain"
            width="300"
            trigger="hover"
            popper-class="dark">
            Enter the exact domain name, including any desired subdomain, that you would like to port in. This procedure requires a dedicated domain that will not be usable for any other purpose.
            <br><br>
            Avoid the use of advertising related terms, such as "ad", "track", and "pixel" as these may increase the likelihood that your domain will blocked by ad blockers.
            <br><br>
            Ex. users.example.org, events.domain.com
            <app-text slot="reference" weight="semi-bold" color="headings" class="tw-mr-space-2 tw-flex tw-items-center">
              Domain
              <i class="el-icon-info tw-ml-space-1" />
            </app-text>
          </el-popover>
          <el-tag
            v-if="isBYOD && domain.status !== 'draft'"
            :type="isVerified ? 'success' : 'info'"
            class="tw-py-space-1 tw-px-space-2 tw-h-auto tw-text-xs tw-font-medium">
            {{ domain.status === 'active' && isDraft ? 'verified' : domain.status }}
          </el-tag>
        </div>
        <el-form-item :rules="domainRules" prop="domain" required>
          <el-input
            ref="input"
            v-scrollable:xy
            :value="domain.domain"
            :disabled="!isDraft"
            size="mini"
            required
            @input="handleInputChange" />
        </el-form-item>
      </label>

      <el-collapse-transition>
        <section v-if="isValid && zones" class="domain-form-instructions tw-mt-layout-3">
          <app-text type="p">
            Add the following records to the DNS configuration of your domain. The procedure for adding records will depend on your DNS service provider. Check with your domain registrar for more information if you are unsure of how to proceed.
          </app-text>
          <el-table v-scrollable:xy :data="zones" class="tw-mt-layout-1 tw-mb-layout-2 tw-w-full" @click.stop>
            <el-table-column
              v-for="col in columns"
              :key="col.prop"
              :prop="col.prop"
              :label="col.label"
              :width="col.width"
              :min-width="col.minWidth">
              <template slot-scope="scope">
                <span
                  v-tippy="{ placement: 'bottom', content: 'Copy', enabled: !hasBeenCopied, showOnInit: false, arrow: true, followCursor: false }"
                  class="tw-p-space-1 tw-whitespace-nowrap tw-inline-block tw-max-w-full tw-overflow-auto"
                  @click="handleCopy(scope.row[col.prop])">
                  {{ scope.row[col.prop] }}
                </span>
              </template>
            </el-table-column>
          </el-table>
          <el-alert
            title="Note:"
            type="info"
            description="Some DNS providers allow additional features to be enabled on records, such as proxying or CNAME flattening. Using these or similar features will interfere with the validation process. Please ensure they are disabled."
            show-icon
            :closable="false" />
        </section>
      </el-collapse-transition>
    </div>
  </el-form>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import AppText from '@/components/AppText.vue';
import CopyToClipboardService from '@/services/copy-to-clipboard-service';
import _cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'DomainForm',
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    AppText
  },
  props: {
    domain: {
      type: Object,
      required: true
    },
    domains: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isValid: false,
      isWarning: false,
      hasBeenCopied: false,
      columns: [
        { prop: 'host', label: 'Host', minWidth: '150' },
        { prop: 'type', label: 'Type', width: '120' },
        { prop: 'value', label: 'Value', minWidth: '200' }
      ]
    };
  },
  computed: {
    isBYOD() {
      return this.domain.type === 'byod';
    },
    isVerified() {
      return this.domain.status === 'active';
    },
    isDraft() {
      return this.domain.status === 'draft' || this.domain.redirector_id === null;
    },
    zones() {
      if (this.domain.zones) return this.domain.zones;
      const found = this.domains.find(d => d.zones);
      if (!found) return null;
      return _cloneDeep(found.zones).map((zone) => {
        zone.host = zone.host.replace(found.domain, this.domain.domain.toLowerCase());
        return zone;
      });
    },
    domainRules() {
      return [
        { required: true, message: 'Please enter a domain', trigger: ['change', 'blur'] },
        { pattern: /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i, message: 'Domain name is not valid', trigger: ['change', 'blur'] },
        { pattern: /^([a-z0-9]+(-[a-z0-9]+)*\.){2,}[a-z]{2,}$/i, message: 'Root domains are not allowed, please add a subdomain', trigger: ['change', 'blur'] },
        { validator: this.checkDomainDuplicates, trigger: ['change', 'blur'] }
      ];
    }
  },
  mounted() {
    if (!this.$supportsTouch) this.$refs.input.focus();
    if (!this.isDraft) this.handleValidate();
    this.$parent.$on('save', this.handleSubmit);
  },
  methods: {
    handleInputChange(value) {
      this.domain.domain = value;
      this.$nextTick(this.handleValidate);
    },
    handleSubmit() {
      this.$emit('save', this.domain);
    },
    handleValidate() {
      this.$refs.form.validate((valid) => {
        this.isValid = valid || this.isWarning;
        this.$parent.$emit('validate', this.isValid);
      });
    },
    async handleCopy(str) {
      if (this.hasBeenCopied) return;
      this.hasBeenCopied = true;
      await CopyToClipboardService.copy(str);
      this.$message({ showClose: true, message: 'Copied to clipboard' });
      document.activeElement.blur();
      setTimeout(() => { this.hasBeenCopied = false; }, 1000);
    },
    checkDomainDuplicates(rule, value, callback) {
      if (!this.isDraft) return callback();

      this.isWarning = false;
      const domains = this.domains.filter(d => d.status !== 'draft' && d.redirector_id !== null && this.domain.redirector_id !== d.redirector_id);

      if (domains.some(d => d.domain.toLowerCase() === value.toLowerCase())) {
        return callback(new Error('This domain already exists'));
      }

      if (domains.some(d => value.includes(`.${d.domain}`))) {
        this.isWarning = true;
        return callback(new Error('You\'ve used this TLD before, are you sure you want to continue?'));
      }

      callback();
    }
  }
};
</script>

<style lang="scss">
.domain-form {
  .el-input input {
    @apply tw-font-semibold;
  }
}

.domain-form-instructions {
  .el-table {
    @apply tw-border tw-border-solid tw-border-jb-grey-50 tw-rounded;

    &::before {
      @apply tw-bg-transparent;
    }

    thead {
      @apply tw-text-grey-darker tw-font-bold;
    }

    .cell {
      @apply tw-px-layout-1 tw-leading-none;
    }

    td,
    th {
      @apply tw-text-center;

      &:first-child {
        @apply tw-text-left;
      }
    }

    .el-table__body .el-table__row {
      &:last-child td {
        @apply tw-border-b-0;
      }

      .cell {
        @apply tw-font-medium tw-text-grey-dark;
      }
    }
  }
}
</style>
